<template>
	<div class="h-full">
		<div class="flex flex-col flex-1 overflow-y-auto pb-24">
			<ContentContainer :animation="$page && $page.isOpen ? 'fade' : 'bounceInUp'" size="small">
				<div class="text-center headline-2-header svg-blue-circle overflow-hidden mt-8">
					<div class="circle-subheadline text-xl text-color-main">
						{{ mainJobField && mainJobField.name }}
					</div>
					<div
						class="relative mx-auto my-6 w-20 h-20 bg-color-main shadow-xl border-4 border-color-white rounded-full"
					>
						<HokIcon
							:size="8"
							:name="`jobfields:${getIconById(mainJobField && mainJobField.identifier)}`"
							class="absolute text-4xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
							color="white"
						/>
					</div>
				</div>
				<h2
					v-if="maxSelected > 0 && currentlySelectedJobFields.length < maxSelected"
					class="text-center smaller"
				>
					Bitte wähle {{ currentlySelectedJobFields.length > 0 ? 'noch ' : '' }}bis zu
					{{ maxSelected - currentlySelectedJobFields.length }}
					{{ currentlySelectedJobFields.length > 0 ? 'weitere' : 'bestimmte' }} Bereiche aus.
				</h2>
				<h2
					v-else-if="maxSelected > 0 && currentlySelectedJobFields.length >= maxSelected"
					class="text-center smaller"
				>
					Du hast bereits {{ maxSelected }} Bereiche ausgewählt.
				</h2>

				<!-- if user can only select e.g. 3 - there is no need for a select all button -->
				<div class="flex">
					<div class="flex-1 underline cursor-pointer" @click="goBack">Zurück zur Übersicht</div>

					<div class="flex-1 text-right">
						<HokCheckbox id="select-all" :key="`all-${selectAll}`" v-model="selectAll">
							Alle auswählen
						</HokCheckbox>
					</div>
				</div>

				<div
					v-for="jobfieldSub in mainJobField && mainJobField.children"
					:key="`${jobfieldSub._id}-${currentlySelectedJobFields.includes(jobfieldSub._id)}`"
				>
					<HokCheckbox
						:id="jobfieldSub._id"
						:checked="currentlySelectedJobFields.includes(jobfieldSub._id)"
						styling="typeform"
						@change="checked => addOrRemoveSubfield(jobfieldSub._id)"
						@input="checked => firstJobSelected()"
					>
						{{ jobfieldSub.name.replace(/\//g, ', ') }}
					</HokCheckbox>
				</div>
			</ContentContainer>
		</div>
		<div v-if="flowDirection === 'popup'" class="shadow p-4">
			<div class="m-auto max-w-5xl text-center">
				<HokButton fullwidth="mobile" color="main" @click="saveSelectedJobFields()">
					Speichern
				</HokButton>
			</div>
		</div>
		<ContentContainer v-else size="large" class="fixed bottom-0 fixed-center pointer-events-none">
			<ButtonRound
				:styling="`${currentlySelectedJobFields.length ? 'b2c' : 'disabled'}`"
				data-cy="saveSelectedJobFieldsButton"
				@click="saveSelectedJobFields()"
			/>
		</ContentContainer>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import type { APIObjectType, APITypeObjectId } from '@hokify/common';
import { EventBus } from '../eventbus';
import ContentContainer from './ContentContainer.vue';
import HokCheckbox from './HokCheckbox.vue';
import ButtonRound from './ButtonRound.vue';
import { getIconById } from '../helpers/icon';

export default defineComponent({
	name: 'SelectJobSubFields',
	components: {
		ContentContainer,
		HokCheckbox,
		ButtonRound
	},
	data() {
		const currentlySelectedJobFields: APITypeObjectId<APIObjectType.JobField>[] = [];
		return {
			amountCurrentlySelectedJobFields: 0,
			currentlySelectedJobFields,
			snackShown: false,
			getIconById,
			EventBus
		};
	},
	emits: ['finished', 'add-selected-job-field', 'remove-selected-job-field', 'go-back'],
	computed: {
		selectAll: {
			get() {
				return (
					this.amountCurrentlySelectedJobFields ===
					(this.mainJobField !== undefined && this.mainJobField.children.length)
				);
			},
			set(checked) {
				if (checked) {
					this.mainJobField?.children.forEach(c => {
						// add not selected
						if (!this.currentlySelectedJobFields.includes(c._id)) {
							this.addOrRemoveSubfield(c._id);
						}
					});
				} else {
					// remove selected
					this.mainJobField?.children.forEach(c => {
						if (this.currentlySelectedJobFields.includes(c._id)) {
							this.addOrRemoveSubfield(c._id);
						}
					});
				}
			}
		}
	},
	created() {
		this.currentlySelectedJobFields = this.selectedJobFields?.map(field => field) || []; // map used for cloning to internal value
	},
	methods: {
		saveSelectedJobFields() {
			this.$emit('finished');
			this.EventBus.$emit('finished');
		},
		addOrRemoveSubfield(idSubfield) {
			// check if there is a maxSelected limit & if limit is reached
			if (
				this.maxSelected > 0 &&
				this.currentlySelectedJobFields.length >= this.maxSelected &&
				!this.currentlySelectedJobFields.includes(idSubfield)
			) {
				this.$snack.danger({
					text: `Max. ${this.maxSelected} Bereiche auswählbar!`,
					button: 'verstanden'
				});
				return;
			}

			// update fields
			if (!this.currentlySelectedJobFields.includes(idSubfield)) {
				this.currentlySelectedJobFields.push(idSubfield);
				this.$emit('add-selected-job-field', idSubfield);
				this.EventBus.$emit('add-selected-job-field', idSubfield);
			} else {
				this.currentlySelectedJobFields.splice(
					this.currentlySelectedJobFields.indexOf(idSubfield),
					1
				);
				this.$emit('remove-selected-job-field', idSubfield);
				this.EventBus.$emit('remove-selected-job-field', idSubfield);
			}
		},
		firstJobSelected() {
			if (this.currentlySelectedJobFields.length > 0 && this.isOnBoarding && !this.snackShown) {
				this.snackShown = true;
				this.$snack.show({
					text: 'Weitere Kategorien können später ausgewählt werden.'
				});
			}
		},
		goBack() {
			this.$emit('go-back');
			this.EventBus.$emit('go-back');
		},
		onChange(newValue: any[]) {
			const validChildrenIds = this.mainJobField?.children.map(c => c._id);
			this.amountCurrentlySelectedJobFields = newValue.filter(childId =>
				validChildrenIds.includes(childId)
			).length; // only count entries for current "root" jobfield
		}
	},
	props: {
		flowDirection: { type: String, default: 'stages' },
		maxSelected: { type: Number, default: null },
		secondTitle: { type: String, default: null },
		selectedJobFields: {
			type: Array as PropType<APITypeObjectId<APIObjectType.JobField>[]>
		},
		mainJobField: { type: Object },
		isOnBoarding: { type: Boolean, default: false }
	},
	watch: {
		currentlySelectedJobFields: [{ immediate: true, deep: true, handler: 'onChange' }]
	}
});
</script>
<style scoped lang="scss">
.fixed-center {
	left: 50%;
	transform: translateX(-50%);
}
</style>
