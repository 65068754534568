<template>
	<div class="mb-0 h-full">
		<!-- MAINFIELDS -->
		<div v-if="stage === 1" key="stage-1" class="h-full">
			<div class="flex flex-col flex-1 overflow-y-auto pb-24">
				<ContentContainer size="large">
					<h2 class="smaller text-center mt-4">
						{{ title }}
					</h2>
					<div class="mx-auto">
						<p
							v-if="
								!hasExperiences &&
								user &&
								user.cvInfo &&
								user.cvInfo.hasExperience !== false &&
								allowNoExperience
							"
							class="underline cursor-pointer inline-block"
							@click="hasNoExperience"
						>
							Du hast noch keine Erfahrung? Klicke Hier!
						</p>
						<p
							v-if="allowToFillGap"
							class="underline cursor-pointer inline-block"
							@click="$emit('fill-experience-gap')"
						>
							Du hast nicht gearbeitet? Dann beschreibe hier, warum du nicht gearbeitet hast.
						</p>
					</div>
					<p v-if="subTitle" class="text-center">
						{{ subTitle }}
					</p>
					<Spinner v-if="loading" size="small" />
					<div v-else class="flex flex-wrap">
						<!-- show selected fields first -->
						<div
							v-for="entry in listOrderedMainFields"
							:key="entry.jobfield._id"
							:class="{ active: entry.selected > 0 }"
							class="py-2 w-1/2 iPhone-6-w-1-3 md:w-1/4 flex flex-col text-center"
							@click="selectMainCategory($event, entry.jobfield)"
						>
							<div
								class="relative rounded-xl shadow-xl flex h-20 w-20 justify-center cursor-pointer mb-3 self-center"
								:class="{ 'bg-color-main': entry.selected > 0 }"
							>
								<div
									v-if="entry.selected"
									class="absolute bg-color-purple text-color-white font-bold text-center rounded-full h-[30px] w-[30px] -right-2.5 -top-2.5 text-sm p-2 leading-[15px]"
								>
									{{ entry.selected }}
								</div>
								<HokIcon
									data-cy="jobfields"
									:size="8"
									:color="`${entry.selected > 0 ? 'white' : 'text'}`"
									class="self-center"
									:name="`jobfields:${getIconById(entry.jobfield.identifier)}`"
								/>
							</div>
							<p class="mb-0 font-bold text-[0.8rem]">
								{{ entry.jobfield.name.replace(/\//g, ', ') }}
							</p>
						</div>
					</div>
				</ContentContainer>
			</div>
			<ContentContainer size="large" class="fixed bottom-0 fixed-center pointer-events-none">
				<ButtonRound
					v-if="flowDirection === 'popup'"
					:styling="`${currentlySelectedFields.length > 0 ? 'b2c' : 'disabled'}`"
					:checkmark="true"
					@click="closeFilter"
				/>
				<ButtonRound
					v-else-if="
						flowDirection === 'stages' && currentlySelectedFields.length > 0 && isOnBoarding
					"
					:checkmark="true"
					@click="setJobFields"
				/>
			</ContentContainer>
		</div>
		<!-- SUBFIELDS -->
		<!-- only load component here if flowDirection === 'stages', otherwise it will be loaded via an insidePage-->
		<SelectJobSubFields
			v-else-if="flowDirection === 'stages' && stage === 2"
			key="stage-2"
			:flow-direction="flowDirection"
			:max-selected="maxSelected"
			:second-title="secondTitle"
			:main-job-field="activeMainJobField"
			:selected-job-fields="currentlySelectedFields"
			:is-on-boarding="isOnBoarding"
			@go-back="stage = 1"
			@finished="finished"
			@add-selected-job-field="addSelectedJobField"
			@remove-selected-job-field="removeSelectedJobField"
		/>
	</div>
</template>
<script lang="ts">
import type {
	APITypeObjectId,
	IAPIJobField,
	IAPIJobFieldTree,
	APIObjectType
} from '@hokify/common';
import type { PropType } from 'vue';
import { defineComponent, markRaw, defineAsyncComponent } from 'vue';
import ContentContainer from './ContentContainer.vue';
import ButtonRound from './ButtonRound.vue';
import SelectJobSubFields from './SelectJobSubFields.vue';
import { getIconById } from '../helpers/icon';
import { EventBus } from '../eventbus';

export default defineComponent({
	name: 'SelectJobFields',
	components: {
		SelectJobSubFields,
		ContentContainer,
		ButtonRound
	},
	emits: ['set-job-fields', 'fill-experience-gap', 'active-main-job-field', 'end-go-to', 'cancel'],
	data() {
		const listOrderedMainFields = undefined as
			| undefined
			| { jobfield: IAPIJobFieldTree; selected: number }[];
		const activeMainJobField: any = undefined;
		const currentlySelectedFields: APITypeObjectId<APIObjectType.JobField>[] =
			(this.selectedJobFields &&
				this.selectedJobFields.map(
					field => (field as IAPIJobField)._id || (field as APITypeObjectId<APIObjectType.JobField>)
				)) ||
			[];

		return {
			loading: false,
			currentlySelectedFields,
			stage: 1,
			activeMainJobField,
			getIconById,
			listOrderedMainFields,
			EventBus
		};
	},
	computed: {
		hasExperiences() {
			if (this.user?.cvInfo?.experiences) {
				return this.user.cvInfo.experiences.length > 0;
			}
			return false;
		}
	},
	created() {
		this.loading = this.jobFields.length === 0; // set loading to true if jobfields is empty (first run)
		this.computeInitialMainFieldList();
	},
	beforeUnmount() {
		this.EventBus.$off('add-selected-job-field');
		this.EventBus.$off('remove-selected-job-field');
		this.EventBus.$off('finished');
		this.EventBus.$off('go-back');
	},
	methods: {
		selectAllMainCategory(field) {
			// find it
			let fieldsTouched = false;
			this.listOrderedMainFields?.some(mainField => {
				if (mainField && mainField.jobfield._id === field._id) {
					if (mainField.jobfield && mainField.jobfield.children && mainField.selected) {
						// deselect[tab
						if (mainField.jobfield.children) {
							mainField.jobfield.children.forEach(child => {
								const childSelected = this.currentlySelectedFields.indexOf(child._id);
								if (childSelected !== -1) {
									this.currentlySelectedFields.splice(childSelected, 1);
									if (!this.maxSelected) {
										fieldsTouched = true;
									}
								}
							});
						}
						mainField.selected = 0;
					} else {
						// select
						if (
							this.maxSelected &&
							mainField.jobfield.children &&
							mainField.jobfield.children.length > this.maxSelected
						) {
							this.$snack.danger({
								text: `Max. ${this.maxSelected} Unterbereiche auswählbar!`,
								button: 'verstanden'
							});
							return true;
						}

						if (mainField.jobfield.children) {
							mainField.jobfield.children.forEach(child => {
								const childSelected = this.currentlySelectedFields.indexOf(child._id);
								if (childSelected === -1) {
									this.currentlySelectedFields.push(child._id);
									fieldsTouched = true;
								}
							});
						}
						mainField.selected =
							(mainField.jobfield.children && mainField.jobfield.children.length) || 0;
					}
					return true;
				}
				return false;
			});

			if (fieldsTouched) {
				this.setJobFields();
			}
		},
		computeMainFieldList() {
			this.listOrderedMainFields?.forEach(mainField => {
				mainField.selected = this.cntSelectedMainfields(mainField.jobfield._id);
			});
		},
		computeInitialMainFieldList() {
			// selected ones
			const selectedElements: { jobfield: IAPIJobFieldTree; selected: number }[] = [];
			const nonSelectedElements: { jobfield: IAPIJobFieldTree; selected: number }[] = [];

			this.jobFields.forEach(jobfield => {
				const selected = this.cntSelectedMainfields(jobfield._id);
				if (selected > 0) {
					selectedElements.push({ jobfield, selected });
				} else {
					nonSelectedElements.push({ jobfield, selected });
				}
			});

			this.listOrderedMainFields = [...selectedElements, ...nonSelectedElements];
		},
		cntSelectedMainfields(jobFieldId) {
			if (this.jobFields) {
				let selected = 0;

				this.jobFields?.some(parent => {
					if (parent.children && parent._id === jobFieldId) {
						parent.children.forEach(child => {
							if (this.currentlySelectedFields.includes(child._id)) {
								selected += 1;
							}
						});
						return true;
					}

					return false;
				});

				return selected;
			}

			return 0;
		},
		async selectMainCategory(_e, jobfield) {
			// only select another main if there are not already 3 subfields
			let notSelected = true;

			// check if clicked main is already selected
			jobfield.children.forEach(child => {
				if (this.currentlySelectedFields.includes(child._id)) {
					notSelected = false;
				}
			});

			// if clicked wasn't already selected and there are already 3 subfields, show error and return
			if (
				this.maxSelected > 0 &&
				this.currentlySelectedFields.length >= this.maxSelected &&
				notSelected
			) {
				this.$snack.danger({
					text: `Max. ${this.maxSelected} Unterbereiche auswählbar!`,
					button: 'verstanden'
				});
				return;
			}

			this.activeMainJobField = jobfield;
			this.$emit('active-main-job-field', jobfield);
			if (this.flowDirection === 'stages') {
				this.changeStage(2);
			} else if (this.flowDirection === 'popup') {
				const subFields = markRaw(defineAsyncComponent(() => import('./SelectJobSubFields.vue')));
				try {
					await this.$page.push(
						subFields,
						{
							flowDirection: this.flowDirection,
							maxSelected: this.maxSelected,
							secondTitle: null,
							mainJobField: this.activeMainJobField,
							selectedJobFields: this.currentlySelectedFields
						},
						{
							modalHeading: this.secondTitle,
							name: 'select-job-sub-fields',
							mode: 'modal',
							done: () => {
								this.EventBus.$off('add-selected-job-field');
								this.EventBus.$off('remove-selected-job-field');
								this.EventBus.$off('finished');
								this.EventBus.$off('go-back');
							}
						}
					);
					this.EventBus.$on('add-selected-job-field', id => this.addSelectedJobField(id));
					this.EventBus.$on('remove-selected-job-field', id => this.removeSelectedJobField(id));
					this.EventBus.$on('finished', () => this.resetStage());
					this.EventBus.$on('go-back', () => {
						this.$page.goBack();
					});
				} catch (err) {
					this.$nuxt.$errorHandler(err);
				}
			}
		},
		closeFilter() {
			this.EventBus.$emit('end-go-to');
		},
		changeStage(stage) {
			// save job fields
			if (stage === 1) {
				this.setJobFields();
			}
			this.$page.scrollToTop();
			this.stage = stage;
			this.updateGoBackHandlerForCurrentPage();
		},
		addSelectedJobField(idSubfield) {
			this.currentlySelectedFields.push(idSubfield);
			// update counter
			this.computeMainFieldList();
		},
		removeSelectedJobField(idSubfield) {
			this.currentlySelectedFields.splice(this.currentlySelectedFields.indexOf(idSubfield), 1);
			// update counter
			this.computeMainFieldList();
		},
		resetStage() {
			this.changeStage(1);
			this.$page.goBack();
		},
		setJobFields() {
			this.$emit('set-job-fields', this.currentlySelectedFields);
			this.EventBus.$emit('set-job-fields', this.currentlySelectedFields);
		},
		updateGoBackHandlerForCurrentPage(forceClosing = false) {
			this.$page.setGoBackHandlerForCurrentPage(() => {
				if (forceClosing) {
					return false;
				}
				// if we can go back: go back & return true
				if (this.stage > 1) {
					// this.setJobfield();
					this.stage -= 1;
					return true;
				}

				// if we are on stage "zero"
				return false;
			});
		},
		finished() {
			this.setJobFields();
			this.$emit('end-go-to');
			this.EventBus.$emit('end-go-to');
		},
		async hasNoExperience() {
			try {
				await this.setHasNoExperience();
				this.$emit('cancel');
				return false;
			} catch (err: any) {
				return this.$nuxt.$errorHandler(
					err,
					'Es ist ein Fehler beim Highlighten der Erfahrung aufgetreten. Bitte versuch es später noch einmal.'
				);
			}
		},
		onChangeSelectedJobFields() {
			this.currentlySelectedFields =
				(this.selectedJobFields &&
					this.selectedJobFields.map(
						field =>
							(field as IAPIJobField)._id || (field as APITypeObjectId<APIObjectType.JobField>)
					)) ||
				[]; //  update currently selected fields to internal working variable
			this.computeInitialMainFieldList();
		},
		onJobFieldsChange() {
			this.loading = false;
			this.computeInitialMainFieldList();
		},
		setHasNoExperience() {
			return this.$nuxt?.$userProfileExperienceStore?.setHasNoExperience();
		}
	},
	props: {
		user: { type: Object, required: true },
		jobFields: { type: Array as PropType<IAPIJobFieldTree[]>, default: () => [] },
		flowDirection: {
			type: String,
			default: 'stages',
			validator: (value: string) => ['stages', 'popup'].includes(value)
		},
		maxSelected: { type: Number, default: 0 },
		title: { type: String, default: '' },
		subTitle: { type: String, default: '' },
		secondTitle: { type: String, default: '' },
		selectedJobFields: { type: Array, default: null },
		isOnBoarding: { type: Boolean, default: false },
		allowNoExperience: { type: Boolean, default: false },
		allowToFillGap: { type: Boolean, default: false }
	},
	watch: {
		selectedJobFields: [
			{
				handler: 'onChangeSelectedJobFields'
			}
		],
		jobFields: [
			{
				handler: 'onJobFieldsChange'
			}
		]
	}
});
</script>
<style scoped lang="scss">
.iPhone-6-w-1-3 {
	@media (min-width: 375px) and (max-width: 767px) {
		width: 33.33333%;
	}
}

.fixed-center {
	left: 50%;
	transform: translateX(-50%);
}
</style>
